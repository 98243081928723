/** @warning 注意：所有jsBridge的回调函数名必须包含"callback"字符，否则打包后会出现函数名被压缩混淆，导致重名覆盖。详情见webpack.prod.ts配置 */
import { aegis } from '@/common/aegis'
import { APP_SCHEME_LINK, APP_SCHEME } from '@/config/constants'
import { compareVersion } from '@/utils'
import queryString from 'query-string'
import session from '../session'
import {
  GetAppKeys,
  ReturnAppDataType,
  ShareConfig,
  Location,
  AddCartGoodsInfo,
  AddReferPageParams
} from './types'
import { wxAppTrack } from '@/common/mall-tracking'

export function getHttpUrlByUrl(url: string) {
  if (url.startsWith(APP_SCHEME_LINK)) {
    return window.decodeURIComponent(url.replace(APP_SCHEME_LINK, ''))
  } else {
    return url
  }
}

export function getNativeUrlByUrl(url: string) {
  if (url.startsWith('http://')) {
    return `${APP_SCHEME_LINK}${window.encodeURIComponent(url)}`
  } else {
    return url
  }
}

/* 跳转页面（在APP里每次会打开新的webview容器） */
export function navigateToByNative(url: string, options?: OpenWindowOptions) {
  // 如果是http链接，则给它填充上APP协议前缀，否则直接跳转（项目代码里传的都是http链接，B端装修配的链接和终端页面有APP协议前缀）
  const openUrl = getNativeUrlByUrl(url)
  console.log('跳转页面：', openUrl)
  sr.app.invoke('openWindow', {
    openUrl,
    setTitle: '',
    needLoading: 0,
    isFullScreen: isAlwaysFullScreen(openUrl, fullScreenPageList),
    isKeepNavBarTransparent: isAlwaysFullScreen(openUrl, fullScreenPageList),
    referComponent: JSON.stringify({ ...wxAppTrack.getCurrentRefer() }),
    ...options
  })
  window.dispatchEvent(new Event('openWindow'))
}

export interface PageTitle {
  title: string
  titleEn?: string
}

/* 根据语言设置页面标题 */
export function setPageTitleByNative(title: string) {
  sr.app.invoke('setTitle', { title })
}

/** 批量获取appData，内部通过版本号对旧版本APP做兼容 */
export async function getAppDataByNative(keys: (keyof GetAppKeys)[]) {
  const appVersion = session.getAppVersion()
  const oldAppVersion = '5.0.22'
  handleSpecialInput(keys) // 处理特殊字段（输入）

  let appData: ReturnAppDataType
  if (appVersion && compareVersion(appVersion, oldAppVersion) === 1) {
    appData = await getAppDataByBridgeNew(keys)
  } else {
    appData = await getAppDataByBridgeOld(keys)
  }
  handleSpecialOutput(appData) // 处理特殊字段（输出）
  console.log('appData转化后的数据：', appData)

  return appData
}

export async function getShareUrl(url = window.location.href) {
  try {
    const appData = await getAppDataByNative(['storeInfoList'])
    const shareUrl = queryString.stringifyUrl({
      url,
      query: {
        systemLanguage: session.getSystemLanguage(),
        storeInfoList: JSON.stringify(appData.storeInfoList)
      }
    })
    return shareUrl
  } catch (e) {
    console.error('转化分享链接失败，错误信息：', e)
    return window.location.href
  }
}

/* 设置页面的分享参数 */
export const setShareConfigByNative = async ({
  shareTitle,
  shareSummary,
  sharePic,
  articleId,
  showCreateImage,
  invokeShare,
  originShareUrl
}: ShareConfig) => {
  // 为了保持分享内容的一致性，这里把系统语言和门店信息带到url参数里
  const shareUrl = await getShareUrl(originShareUrl)
  console.log('【分享参数】', {
    标题: shareTitle,
    描述: shareSummary,
    链接: shareUrl,
    图片: sharePic,
    文章id: articleId,
    '是否显示"一键生成图片"': showCreateImage,
    是否主动调起分享界面: invokeShare
  })
  sr.app.invoke('configShareInfo', {
    shareTitle,
    shareSummary,
    sharePic,
    shareUrl,
    articleId,
    showCreateImage,
    invokeShare
  })
}

/* 设置导航栏 */
export function setNavigationBtnsByNative(otherItems: string) {
  sr.app.invoke('setNavigationRightItem', {
    otherItems, // 配置右侧button，share代表分享，cart代表购物车，多选用英文逗号分割。
    title: '', // title配置则走原逻辑，不会加载otherItems的配置
    link: 'link',
    isShow: true
  })
}

/** 需要通栏显示的页面列表 */
const fullScreenPageList = [
  '/explore-sams-list.html', // 探索列表页
  '/top-detail.html' // 榜单详情页
]

function isAlwaysFullScreen(path: string, pageList: string[]) {
  const oldAppVersion = '5.0.34'
  if (compareVersion(session.getAppVersion(), oldAppVersion) !== 1) return false

  let h5Path = path
  const index = path.indexOf(APP_SCHEME_LINK)
  if (index >= 0) {
    h5Path = decodeURIComponent(path.slice(index))
  }
  return pageList.some((route) => h5Path.includes(route))
}

export interface OpenWindowOptions {
  setTitle?: string
  needLoading?: 0 | 1
  isFullScreen?: boolean | 0 | 1
  isKeepNavBarTransparent?: boolean
  closeLastPage?: boolean // 关闭本页面
}

/* 显示loading */
export const showLoadingByNative = (loading: boolean) => {
  sr.app.invoke('showLoading', {
    showLoading: loading
  })
}

/* 通知提示框 */
export function showToastByNative(content: string) {
  sr.app.invoke('showToast', {
    toastInfo: content
  })
}

/** 添加购物车 */
export const addCartByNative = (goods: AddCartGoodsInfo[]) => {
  sr.app.invoke('addCart', {
    goodsInfo: JSON.stringify(goods),
    callback: () => {} // 临时适配APP新版本的代码，待0512合流后覆盖这行
  })
}

/** 跳转到商详页 */
export const goToGoodsDetailPageByNative = (spuId: string) => {
  navigateToByNative(`${APP_SCHEME}goods_detail?spuId=${spuId}`)
}

/** 获取经纬度数据 */
export async function getLocationByNative() {
  return new Promise<Location>((resolve) => {
    const defaultLocation = { longitude: 0, latitude: 0 }

    const locationCallback = (location: string) => {
      try {
        resolve(JSON.parse(location))
      } catch (err) {
        aegis.error(err)
        console.error('转化locationInfo的json串失败：', err)
        resolve(defaultLocation)
      }
    }

    try {
      sr.app.invoke(
        'locationInfo',
        {
          callback: locationCallback
        },
        JSON.stringify(defaultLocation)
      )
    } catch (err) {
      aegis.error(err)
      console.error('调用locationInfo失败:', err)
      resolve(defaultLocation)
    }
  })
}

/** 获取首页收货地址的经纬度数据（可手动更改） */
export async function getDeliveryAddressLocationByNative() {
  return new Promise<Location>((resolve) => {
    const defaultLocation = { longitude: 0, latitude: 0 }

    const deliveryAddressLocationCallback = (location: string) => {
      try {
        resolve(JSON.parse(location))
      } catch (err) {
        aegis.error(err)
        console.error('转化getDeliveryAddressLocation的json串失败：', err)
        resolve(defaultLocation)
      }
    }

    try {
      sr.app.invoke(
        'getDeliveryAddressLocation',
        {
          callback: deliveryAddressLocationCallback
        },
        JSON.stringify(defaultLocation)
      )
    } catch (err) {
      aegis.error(err)
      console.error('调用getDeliveryAddressLocation失败:', err)
      resolve(defaultLocation)
    }
  })
}

/** 获取装修页骨架数据 */
export async function getHomePageCacheDataByNative() {
  return new Promise<any>((resolve) => {
    const defaultFrameJson = '{}'
    const jsonCallback = (data: any) => {
      try {
        const resData = JSON.parse(data)
        if (resData.pageContentVO) {
          resData.pageContentVO.atmosphereImage = JSON.parse(resData.pageContentVO.atmosphereImage)
          resData.pageContentVO.bgColor = JSON.parse(resData.pageContentVO.bgColor)
          resData.pageContentVO.pageShareImage = JSON.parse(resData.pageContentVO.pageShareImage)
        }

        resData.pageModuleCacheVOList = resData.pageModuleCacheVOList?.map(
          ({ anchorContent, moduleContent, bizStyle, ...props }: any) => {
            return {
              ...props,
              anchorContent: JSON.parse(anchorContent),
              moduleContent: JSON.parse(moduleContent),
              bizStyle: JSON.parse(bizStyle)
            }
          }
        )
        resolve(resData)
      } catch (err) {
        console.error('页面骨架信息json串转义失败：', err)
        console.log('不合法的json：', [data])
        resolve('')
      }
    }
    try {
      sr.app.invoke(
        'samsDiscoveryDefaultFrameJson',
        {
          callback: jsonCallback
        },
        defaultFrameJson
      )
    } catch (err) {
      console.error(err, '获取页面骨架信息报错')
      resolve('')
    }
  })
}

/** 兼容性处理，将旧版本的chan_shop_id转化成storeInfoList */
export function getStoreInfoList(storeInfoList: GetAppKeys['storeInfoList'], chan_shop_id: string) {
  if (Array.isArray(storeInfoList) && storeInfoList.length > 0) {
    console.log('返回appData里的storeInfoList')
    return storeInfoList
  } else if (chan_shop_id) {
    console.log('转化chan_shop_id并返回')
    return chan_shop_id
      .split(',')
      .filter(Boolean)
      .map((id) => ({ storeId: Number(id) }))
  } else {
    return []
  }
}

/** 处理appData里特殊的字段值（输入） */
function handleSpecialInput(keys: (keyof GetAppKeys)[]) {
  // 当单独获取storeInfoList时，把chan_shop_id也带上，因为旧版本要根据chan_shop_id生成storeInfoList
  if (keys.includes('storeInfoList') && !keys.includes('chan_shop_id')) {
    keys.push('chan_shop_id')
  }

  // 处理终端错误的merber_id拼接
  const memberIdIndex = keys.indexOf('member_id')
  if (memberIdIndex >= 0) {
    keys.splice(memberIdIndex, 1, 'merber_id' as keyof GetAppKeys)
  }
}

/** 处理appData里特殊的字段值（输出） */
function handleSpecialOutput(appData: ReturnAppDataType) {
  // storeInfoList在低版本app是空字符串，在高版本app是数组
  if ('storeInfoList' in appData) {
    // 在输入时做过处理，这里一定会有chan_shop_id
    appData.storeInfoList = getStoreInfoList(appData.storeInfoList, appData.chan_shop_id as string)
    console.log('appData里的storeInfoList', appData.storeInfoList)
  }

  // 处理终端错误的merber_id拼接
  if ('merber_id' in appData) {
    appData.member_id = appData['merber_id' as keyof GetAppKeys] as ReturnAppDataType['member_id']
    delete appData['merber_id' as keyof GetAppKeys]
  }
}

/** 批量获取appData，旧版APP的方法 */
export async function getAppDataByBridgeOld(keys: (keyof GetAppKeys)[]) {
  return new Promise<ReturnAppDataType>((resolve) => {
    /** callback调用的次数计数 */
    let count = 0
    const { length } = keys
    /** promise返回的数据 */
    let result: ReturnAppDataType = {}

    // 每次调用getAppData公用的callback(不用担心覆盖的问题，因为逻辑是一样的)
    function getAppDataCallback(jsonResult: string) {
      // 将json数据转成object
      let objResult = {}
      try {
        objResult = JSON.parse(jsonResult)
      } catch (err) {
        console.error('getAppData转化json失败：', err)
        // todo: 上报错误
      }

      // 将返回值存入map
      result = { ...result, ...objResult }
      count += 1
      // 如果callback调用完成则resolve
      if (count === length) {
        resolve(result)
      }
    }

    // 循环调用jsBridge
    keys.forEach((key) => {
      try {
        sr.app.invoke('getAppData', {
          key,
          callback: getAppDataCallback
        })
      } catch (err) {
        console.error('getAppData调用错误：', err)
        resolve({})
      }
    })
  })
}

/** 批量获取appData，新版APP的方法 */
export async function getAppDataByBridgeNew(keys: (keyof GetAppKeys)[]) {
  return new Promise<ReturnAppDataType>((resolve) => {
    function getAppDataCallback(jsonResult: string) {
      try {
        const appData = JSON.parse(jsonResult)
        resolve(appData)
      } catch (err) {
        console.error('getAppData转化json失败：', err)
        console.log('appData的json数据：', jsonResult)
        // todo: 上报错误
      }
    }

    try {
      sr.app.invoke('getAppData', {
        key: keys.join(','),
        callback: getAppDataCallback
      })
    } catch (err) {
      console.error('getAppData调用错误：', err)
      resolve({})
    }
  })
}

export interface NativeSwiperParams {
  selectIndex: number
  dataList: {
    isVideo: boolean
    videoUrl: string
    coverUrl: string
    imageUrl: string
  }[]
}
export const showImageByNative = (params: NativeSwiperParams) => {
  console.log('调用原生的看大图方法showImage，参数为：', params)
  sr.app.invoke('showImage', params)
}

export const sharePoster = (params: { image: string }) => {
  const { image } = params
  try {
    sr.app.invoke('sharePoster', {
      image
    })
  } catch (err) {
    aegis.error(err)
  }
}

export function addReferPageByNative(params: AddReferPageParams) {
  const oldAppVersion = '5.0.49'
  const currentAppVersion = session.getAppVersion()
  if (compareVersion(currentAppVersion, oldAppVersion) === 1) {
    console.warn('传给终端的refer_page', params)
    sr.app.invoke('addReferPage', params)
  }
}
