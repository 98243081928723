import {
  PageShare,
  Element,
  BrowseAndLeavePage,
  CommoditySku,
  SkuToCart,
  GetCoupon,
  Order,
  Search,
  Live2goodParams,
  ExposeTaskParams,
  ExposeEquityParams,
  ExposeInviteActivityParams,
  Component
} from './types'
/**
 * 业务埋点定义
 */
import { SendKeyEnum, wxAppTrack } from './wx-app-track'
import { srInstance } from '../mall-tracking-sr/sr-sdk-instance'
import { isInApp } from '@/utils'
import { aegis } from '@/common/aegis'

/**
 * 页面分享
 * @param page
 * @param pageOrComponentThis
 */
function sharePage(page: PageShare, pageOrComponentThis?: any) {
  wxAppTrack.send<PageShare>(SendKeyEnum.PageShareAppMessage, page, pageOrComponentThis)
}

/**
 * 元素事件
 * @param element
 * @param pageOrComponentThis
 */
function element(element: Element, pageOrComponentThis?: any) {
  wxAppTrack.send<Element>(SendKeyEnum.Element, element, pageOrComponentThis)
}

/**
 * 页面浏览
 * @param params
 * @param pageOrComponentThis
 */
function webBrowsePage(params: BrowseAndLeavePage, pageOrComponentThis?: any) {
  // wxAppTrack.send<BrowseAndLeavePage>(SendKeyEnum.WebBrowsePage, params, pageOrComponentThis)
  wxAppTrack.pageOnShow(SendKeyEnum.WebBrowsePage, window, params, pageOrComponentThis)
}

/**
 * 页面浏览2
 * @param params
 * @param pageOrComponentThis
 */
function browseWxappPage(params: BrowseAndLeavePage, pageOrComponentThis?: any) {
  // wxAppTrack.send<BrowseAndLeavePage>(SendKeyEnum.BrowseWxappPage, params, pageOrComponentThis)
  wxAppTrack.pageOnShow(SendKeyEnum.BrowseWxappPage, window, params, pageOrComponentThis)
}

/**
 * 页面退出
 * @param params
 * @param pageOrComponentThis
 */
function leavePage(params: BrowseAndLeavePage, pageOrComponentThis?: any) {
  // wxAppTrack.send<BrowseAndLeavePage>(SendKeyEnum.LeavePage, params, pageOrComponentThis)
  wxAppTrack.pageOnHide(SendKeyEnum.LeavePage, window, params, pageOrComponentThis)
}

/**
 * 页面退出2
 * @param params
 * @param pageOrComponentThis
 */
function LeaveWxappPage(params: BrowseAndLeavePage, pageOrComponentThis?: any) {
  // wxAppTrack.send<BrowseAndLeavePage>(SendKeyEnum.LeaveWxappPage, params, pageOrComponentThis)
  wxAppTrack.pageOnHide(SendKeyEnum.LeaveWxappPage, window, params, pageOrComponentThis)
}

/**
 * 商品页浏览
 * @param browseSku
 * @param pageOrComponentThis
 */
function browseSkuPage(browseSku: CommoditySku, pageOrComponentThis?: any) {
  wxAppTrack.send<CommoditySku>(SendKeyEnum.BrowseSkuPage, browseSku, pageOrComponentThis)
}

/**
 * 商品曝光
 * @param exposeSku
 * @param pageOrComponentThis
 */
function exposeSkuComponent(exposeSku: CommoditySku, pageOrComponentThis?: any) {
  wxAppTrack.send<CommoditySku>(SendKeyEnum.ExposeSkuComponent, exposeSku, pageOrComponentThis)
}

/**
 * 商品卡触发（即点击）
 * @param triggerSku
 * @param pageOrComponentThis
 */
function triggerSkuComponent(triggerSku: CommoditySku, pageOrComponentThis?: any) {
  wxAppTrack.send<CommoditySku>(SendKeyEnum.TriggerSkuComponent, triggerSku, pageOrComponentThis)
}

/**
 * 加购
 * @param addSku
 * @param pageOrComponentThis
 */
function addToCart(addSku: SkuToCart, pageOrComponentThis: any) {
  wxAppTrack.send<SkuToCart>(SendKeyEnum.AddToCart, addSku, pageOrComponentThis)
}

/**
 * 领取优惠券
 * @param coupon
 * @param pageOrComponentThis
 */
function getCoupon(coupon: GetCoupon, pageOrComponentThis?: any) {
  wxAppTrack.send<GetCoupon>(SendKeyEnum.GetCoupon, coupon, pageOrComponentThis)
}

/**
 * 订单状态变化
 * @param order
 * @param pageOrComponentThis
 */
function customOrder(order: Order, pageOrComponentThis?: any) {
  wxAppTrack.send<Order>(SendKeyEnum.CustomOrder, order, pageOrComponentThis)
}

/**
 * 搜索
 * @param search
 * @param pageOrComponentThis
 */
function search(search: Search, pageOrComponentThis?: any) {
  wxAppTrack.send<Search>(SendKeyEnum.Search, search, pageOrComponentThis)
}
/**
 * 直播 跳转商品详情页
 * @param detail
 * @param pageOrComponentThis
 */
function live2GoodDetail(detail: Live2goodParams, pageOrComponentThis?: any) {
  wxAppTrack.send<Live2goodParams>(SendKeyEnum.LiveBrowseSkuPage, detail, pageOrComponentThis)
}
/**
 * 直播 点击跳转直播间
 * @param detail
 * @param pageOrComponentThis
 */
function browseLivePage(detail: Live2goodParams, pageOrComponentThis?: any) {
  wxAppTrack.send<Live2goodParams>(SendKeyEnum.BrowseLivePage, detail, pageOrComponentThis)
}
/**
 * 任务组件曝光
 * @param exposeSku
 * @param pageOrComponentThis
 */
function exposeTaskComponent(exposeTask: ExposeTaskParams, pageOrComponentThis?: any) {
  wxAppTrack.send<ExposeTaskParams>(SendKeyEnum.ExposeSkuComponent, exposeTask, pageOrComponentThis)
}
/**
 * 权益中心权益组件曝光
 * @param exposeEquity
 * @param pageOrComponentThis
 */
function exposeEquityComponent(exposeEquity: ExposeEquityParams, pageOrComponentThis?: any) {
  wxAppTrack.send<ExposeTaskParams>(
    SendKeyEnum.ExposeSkuComponent,
    exposeEquity,
    pageOrComponentThis
  )
}

/**
 * 邀请有礼任务组件曝光
 * @param exposeSku
 * @param pageOrComponentThis
 */
function exposeInviteActivityComponent(
  exposeSku: ExposeInviteActivityParams,
  pageOrComponentThis?: any
) {
  wxAppTrack.send<ExposeInviteActivityParams>(
    SendKeyEnum.ExposeSkuComponent,
    exposeSku,
    pageOrComponentThis
  )
}

/**
 * 组件曝光
 * @param component
 * @param extProps
 */
function exposeComponent(component: Component, extProps?: any) {
  wxAppTrack.send<Component>(SendKeyEnum.ExposeSkuComponent, component, {
    isComponent: true,
    ...extProps
  })
}

/**
 * 广告曝光事件
 * @param element
 * @param pageOrComponentThis
 */
function exposeAds(element: Component, pageOrComponentThis?: any) {
  wxAppTrack.send<Component>(SendKeyEnum.ExposeAds, element, pageOrComponentThis)
}

/**
 * 广告点击事件
 * @param element
 * @param pageOrComponentThis
 */
function triggerAd(element: Component, pageOrComponentThis?: any) {
  wxAppTrack.send<Component>(SendKeyEnum.TriggerAd, element, pageOrComponentThis)
}

async function asyncLeaveWxappPage(props: Record<string, any>, extProps: Record<string, any>) {
  await LeaveWxappPage(props, extProps)
}

/**
 * APP内埋点离开事件 fetch手动上报形式
 * @param props
 * @param extProps
 */
async function fetchLeaveWxappPage(props: Record<string, any>, extProps: Record<string, any>) {
  // 不在APP内直接return 不做处理
  if (!isInApp()) return
  // 先将队列里的埋点上报
  srInstance.flush()
  // 往埋点队列里添加一个要离开埋点 需要等待加入到队列中
  await asyncLeaveWxappPage(props, extProps)
  //  获取最后一个埋点
  const lastIndex = srInstance.queue.stack.length - 1
  const currentReport = srInstance.queue.stack?.[lastIndex]
  // 清空队列里的埋点
  srInstance.queue.clean()

  // 如果队列没有数据 不发送请求 也关闭页面
  if (!currentReport) {
    aegis.error('离开页面事件埋点上报参数失败', srInstance.queue.stack)
    sr.app.invoke('closeWindow')
    return
  }
  // 封装埋点数据  测试要求按照原来的格式上报
  const order = [
    'page',
    'page_title',
    'page_id',
    'page_type',
    'event_name',
    'timeIn',
    'title',
    'path',
    'query',
    'name',
    'activityInfo',
    'timeOut',
    'stay_time',
    'log_id',
    'time',
    'wx_user',
    'chan',
    'platform',
    'sdk_version',
    'system_info',
    'member_card_type',
    'abtest_code',
    'refer'
  ]
  const propsData = {
    ...currentReport.props,
    ...srInstance.context
  }
  const orderedObj: Record<string, any> = {}
  order.forEach((key) => {
    if (propsData.hasOwnProperty(key)) {
      orderedObj[key] = propsData?.[key] || 'default'
    }
  })
  const reportData = JSON.stringify([
    {
      type: SendKeyEnum.LeaveWxappPage,
      props: orderedObj,
      tracking_id: srInstance.tracking_id,
      from: 'sr-sdk-js'
    }
  ])
  // 手动上报埋点数据
  try {
    const url = `${srInstance.option.serverUrl}?token=${import.meta.env.VITE_YOUSHU_TOKEN}`
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: reportData,
      keepalive: true
    })
    // 等待发送后关闭窗口
    sr.app.invoke('closeWindow')
  } catch (e) {
    //  失败 也直接关闭当前窗口
    aegis.error('离开页面事件埋点上报参数失败', e)
    sr.app.invoke('closeWindow')
  }
}

export {
  sharePage,
  element,
  webBrowsePage,
  leavePage,
  browseSkuPage,
  exposeSkuComponent,
  triggerSkuComponent,
  addToCart,
  getCoupon,
  customOrder,
  search,
  live2GoodDetail,
  browseLivePage,
  exposeTaskComponent,
  browseWxappPage,
  LeaveWxappPage,
  exposeEquityComponent,
  exposeInviteActivityComponent,
  exposeComponent,
  exposeAds,
  triggerAd,
  fetchLeaveWxappPage,
  wxAppTrack
}
