import { api } from './../config/api/index'
import { makeAutoObservable, runInAction } from 'mobx'
import { apis } from '@/common/http-client'
import { RedeemCodeStateEnum, RenewData, RenewTypeEnum, RESPONSE_CODE } from '@/config'
import { MemInfo, MemType, CardStatusType, LifeRightsData } from '@/config/interface'
import dayjs from 'dayjs'
import { Toast } from '@/components/Toast'
import { MessageDecrypt, multiTranslation } from '@/utils'

class EquityStore {
  memberInfo: MemInfo = {}
  effectiveEndTime = 0 // 平安权益过期时间
  currentTime = 0 // 服务器当前时间
  isReceived = false // 卓越会籍是否领取了平安权益
  isExpire = false // 会籍是否过期
  isSvip: undefined | boolean = undefined // 是否为卓越会员
  isFreeze = false // 会员卡是否冻结
  isBanned = false // 是否永久封卡
  usedAmount = 0 // 已使用优惠金额
  usedFreightAmount = 0 // 已使用的网购运费券（分）
  usedIntegralAmount = 0 // 已使用的积分返利金额（分）
  lifeRights = {
    mainTitleCn: '',
    mainTitleEn: '',
    subtitleCn: '',
    subtitleEn: '',
    carWashCopy: '',
    equityContent: [],
    equityCenterPopUp: false,
    viewMyBenefits: false,
    jumpLinkUrl: ''
  } // 生活权益
  renewList: RenewData[] = []
  renewData: RenewData = { deductAmount: 0, originalAmount: 0, renewAmount: 0, renewType: 0 }
  // 是否cash白名单
  isCashGrayUser = false
  hkMemberInfo: any = {}
  constructor() {
    makeAutoObservable(this)
  }
  async queryRenewInfo() {
    Toast.loading()
    try {
      const { code, data, msg } = await apis.saveMoney.getRenewType()
      Toast.hide()
      if (code === RESPONSE_CODE.SUCCESS) {
        const { memInfo, list = [] } = data
        const { memType, expireTime, cardStatus, banned, cardType } = memInfo
        memInfo.memName = MessageDecrypt(memInfo.memName)
        this.memberInfo = memInfo
        this.isSvip = Number(memType) === Number(MemType.PREMIUM)
        // this.isSvip = true
        this.isExpire = expireTime - dayjs().valueOf() <= 0
        // this.isExpire = true
        this.isFreeze = cardStatus === CardStatusType.FREEZE
        this.isBanned = banned
        this.renewList = list
        let renewType = this.isSvip
          ? RenewTypeEnum.PLUS_AUTO_RENEW
          : RenewTypeEnum.GENERAL_AUTO_RENEW
        // if (!this.isExpire) {
        //   // 会籍没过期，显示卓越续费数据
        //   renewType = RenewTypeEnum.PLUS_AUTO_RENEW
        // }
        if (this.isSvip) {
          if (memInfo.autoRenew) {
            // 卓越卡，已开通自动续费，显示卓越年费
            renewType = RenewTypeEnum.PLUS_RENEW
          } else {
            // 卓越卡，未开通自动续费，显示卓越连续包年
            renewType = RenewTypeEnum.PLUS_AUTO_RENEW
          }
        } else {
          if (this.isExpire) {
            // 已过期
            if (memInfo.autoRenew) {
              // 已经开通了自动续费,普卡过期显示普卡年费价格
              renewType = RenewTypeEnum.GENERAL_RENEW
            } else {
              // 未开通自动续费,普卡过期显示普卡连续包年价格
              renewType = RenewTypeEnum.GENERAL_AUTO_RENEW
            }
          } else {
            // 未过期，显示卓越连续包年
            renewType = RenewTypeEnum.PLUS_AUTO_RENEW
            //  商业卡没有自动续费
            if (cardType === 7) {
              renewType = RenewTypeEnum.PLUS_RENEW
            }
          }
        }

        const renewDataFilter = this.renewList.filter((item) => {
          return item.renewType === renewType
        })

        this.renewData = renewDataFilter[0] ||
          this.renewList[0] || {
            deductAmount: 0,
            originalAmount: 0,
            renewAmount: 0,
            renewType: 0
          }
        // this.renewData = {
        //   deductAmount: 1,
        //   originalAmount: 1,
        //   renewAmount: 1,
        //   renewType: 1
        // }
        if (this.isSvip) {
          this.querySaveMoney()
          // this.queryPingan() 注释平安权益接口请求
        }
      } else {
        Toast.fail(multiTranslation(msg || '请稍后再试'), 3000)
        setTimeout(() => {
          sr.app.invoke('closeWindow')
        }, 3000)
      }
    } catch (error: any) {
      Toast.hide()
      console.log(error)
    }
  }
  // 查询卓越省钱计算器信息
  async querySaveMoney() {
    const { code, data } = await apis.saveMoney.saveMoneyInfoPlus()
    if (code === RESPONSE_CODE.SUCCESS) {
      const { usedAmount, usedFreightAmount, usedIntegralAmount } = data
      this.usedAmount = usedAmount
      this.usedFreightAmount = usedFreightAmount
      this.usedIntegralAmount = usedIntegralAmount
    }
  }
  // 查询平安权益
  async queryPingan() {
    const { code, data } = await apis.user.getRedeemCodes({
      type: 'pingan'
    })
    if (code === RESPONSE_CODE.SUCCESS) {
      const receivedData = data.filter((item: any) => {
        return item.redeemCodeState === RedeemCodeStateEnum.RECEIVED
      })
      // 只会有一条已领取
      this.effectiveEndTime = receivedData[0] ? receivedData[0].effectiveEndTime : 0
      this.currentTime = receivedData[0] ? receivedData[0].currentTime : 0

      // 有记录就算已领取过权益
      this.isReceived = data && data.length > 0
    }
  }

  // 查询生活权益
  async queryLifeRights() {
    const { code, data } = await apis.user.getEquityCenterConfig()
    if (code === RESPONSE_CODE.SUCCESS && data) {
      this.lifeRights = data
    }
  }

  async fetchHkmemberInfo() {
    const { code, data } = await apis.user.getHkmemberInfo()
    if (code === RESPONSE_CODE.SUCCESS && data) {
      this.hkMemberInfo = data
    }
  }
}

export default new EquityStore()
