import { Chan, Context } from './types/chan'
import { EnumChannel, SendKeyEnum, dataTracking } from './data-tracking'
import { User } from './types/user'
import { isHarmony, isInApp, isInMiniProgram, isIOS } from '@/utils'
import { PageInfo, RefPageInfo } from '../mall-tracking/types'
import { SystemInfo } from './types/system'
import {
  getChan,
  getUser,
  getSystemInfo,
  getAppDataByBridge,
  addReferPage,
  getRefer,
  getNativeProperties
} from '../jsBridge/jsBridgeApi'
import { CardTypeEnum } from '@/config'
import { srInstance } from '../mall-tracking-sr/sr-sdk-instance'

class WxAppTrack {
  maxReferLength = 20

  systemInfo: SystemInfo = {}
  /** 当前页面的基本埋点信息 */
  private currentPageInfo: any
  /** 小程序传入的refer信息 */
  private _miniProgramRefer: RefPageInfo

  private callBack: (obj: Context) => void

  constructor() {
    this.currentPageInfo = {
      page_title: 'default',
      page_type: 'default',
      page_id: 'default'
    }
    this._miniProgramRefer = {
      ref_page_title: 'default',
      ref_page_type: 'default',
      ref_page_id: 'default'
    }
    this.callBack = () => {}
  }

  set page_title(page_title: string) {
    this.currentPageInfo.page_title = page_title
  }

  get page_title() {
    return this.currentPageInfo.page_title
  }

  set page_type(page_type: string) {
    this.currentPageInfo.page_type = page_type
  }

  get page_type() {
    return this.currentPageInfo.page_type
  }

  set page_id(page_id: string) {
    this.currentPageInfo.page_id = page_id
  }

  get page_id() {
    return this.currentPageInfo.page_id
  }

  set miniProgramRefer(refer: RefPageInfo) {
    this._miniProgramRefer = refer
  }

  get miniProgramRefer() {
    return this._miniProgramRefer
  }

  /** 拼入当前链路，返回下一级的refer */
  get miniProgramNextRefer(): RefPageInfo {
    let refer = {
      ref_page_title: this.miniProgramRefer.ref_page_title + `/${this.page_title}`,
      ref_page_type: this.miniProgramRefer.ref_page_type + `/${this.page_type}`,
      ref_page_id: this.miniProgramRefer.ref_page_id + `/${this.page_id}`
    }
    if (this.miniProgramRefer.ref_page_title.split('/').length >= this.maxReferLength) {
      refer = {
        ref_page_title: this.calcRefer(this.miniProgramRefer.ref_page_title, this.page_title),
        ref_page_type: this.calcRefer(this.miniProgramRefer.ref_page_type, this.page_type),
        ref_page_id: this.calcRefer(this.miniProgramRefer.ref_page_id, this.page_id)
      }
    }
    try {
      const appDataStr = sessionStorage.getItem('appData')
      if (appDataStr) {
        const appData = JSON.parse(appDataStr || '{}')
        appData.refer = refer
        sessionStorage.setItem('appData', JSON.stringify(appData))
      }
    } catch (error) {}
    return refer
  }

  /** 小程序链路如超过20级，默认从第三级开始删除 */
  /** @return {string} 返回20级计算后新的链路 */
  calcRefer(oldPath: string, newPath: string, deleteIndex = 2) {
    const list = oldPath.split('/')
    list.splice(deleteIndex, 1)
    list.push(newPath)
    return list.join('/')
  }

  start() {
    dataTracking.start()
  }

  /**
   *  设置用户信息
   * @param user
   */
  setUser(user?: User, channel?: EnumChannel) {
    if (user) {
      dataTracking.setUser(user, channel)
    } else {
      getUser().then((user) => {
        if (!isInMiniProgram()) {
          // 不在小程序打开的页面，不上传union_id
          user.union_id = undefined
        }
        dataTracking.setUser(user, channel)
      })
    }
  }

  /**
   * 设置abtest
   */
  setAbTest(extAbTCodes: any[] = []) {
    getNativeProperties().then((propertiesInfo) => {
      const { abtest_code = [] } = propertiesInfo
      const expIds = new Set()
      const abtCodes = [...abtest_code, ...extAbTCodes].filter((item) => {
        if (expIds.has(item.expId)) {
          return false
        } else {
          expIds.add(item.expId)
          return true
        }
      })
      abtCodes.length && this.setContext({ abtest_code: abtCodes })
    })
  }

  /**
   *  获取终端更新终端refer 和 tracking_id
   */
  async setAppReferAndTrackingId() {
    const propertiesInfo = await getNativeProperties()
    const { refer, tracking_id } = propertiesInfo
    if (refer) {
      this.setRefer(refer)
    }
    if (tracking_id) {
      srInstance.tracking_id = tracking_id
    }
  }

  /**
   *  设置系统信息
   * @param user
   */
  setSystemInfo() {
    try {
      getSystemInfo().then((systemInfo: any) => {
        const { cardType } = systemInfo
        delete systemInfo.cardType
        this.systemInfo = systemInfo
        this.setContext({
          system_info: systemInfo,
          member_card_type: cardType ? CardTypeEnum[Number(cardType)] : 'default'
        })
      })
    } catch (err) {
      console.log('getAppData调用错误：', err)
    }
  }

  /**
   * 设置渠道信息
   * @param chan
   * @param channel
   */
  setChan(chan?: Chan, channel: EnumChannel = EnumChannel.sr) {
    if (chan) {
      dataTracking.setChan(chan, channel)
    } else {
      getChan().then((chan) => {
        // 获取不到店铺id和名称时上报default
        if (!chan.chanShopId) {
          chan.chanShopId = 'default'
        }
        if (!chan.chanShopName) {
          chan.chanShopName = 'default'
        }
        dataTracking.setChan(chan, channel)
      })
    }
  }

  /**
   * 设置APP内渠道
   */
  setAppChan() {
    getNativeProperties().then((propertiesInfo) => {
      const { chan = {} } = propertiesInfo
      this.setContext({ chan })
    })
  }

  /**
   * 设置通用上下文内容
   * @param obj
   * @param channel
   */
  setContext(obj: Context, channel: EnumChannel = EnumChannel.sr) {
    dataTracking.setContext(obj, channel)
  }

  /**
   * 事件上报
   * @param sendKey
   * @param obj
   * @param pageOrComponentInstance
   * @param channel
   */
  // eslint-disable-next-line max-params
  async send<T extends Record<string, any>>(
    sendKey: SendKeyEnum,
    obj: T,
    extProps: any = {},
    channel?: any
  ) {
    const pageInfo = await this.getCurrentPageInfo(null)
    dataTracking.send<T>(sendKey, obj, channel, {
      ...pageInfo,
      ...extProps
    })
  }

  /**
   * 初始化加载，设置用户信息
   *
   * @memberof WxAppTrack
   */
  appOnLaungh() {
    if (isInMiniProgram()) {
      this.setUser()
      // 只有小程序端的getAppData会返回refer
      this.setRefer()
      //  在小程序内公参缺失
      this.setChan()
      this.setSystemInfo()
    }
    if (isInApp()) {
      this.setUser()
      this.setAppChan()
      this.setSystemInfo()
      this.setAbTest()
    }

    this.setContext({
      platform: isInApp() ? (isHarmony() ? 'HarmonyOS' : isIOS() ? 'ios' : 'android') : 'h5',
      sdk_version: '1.0.0'
    })
    // 手动开启上报
    this.start()
  }

  /**
   * 页面浏览
   * @param pageThis
   * @param channel
   */
  // eslint-disable-next-line max-params
  async pageOnShow(
    sendKey: SendKeyEnum,
    pageThis: any,
    props: Record<string, any>,
    extProps: Record<string, any>,
    channel: EnumChannel = EnumChannel.sr
  ) {
    pageThis._page_start_time = Date.now() // 记录页面开始时间
    // console.log('记录页面开始时间', Date.now())
    if (isInApp()) {
      const { refer_page } = await getAppDataByBridge(['refer_page'])
      const { is_newly_open = true } = props
      props.refer_page = refer_page
      props.is_newly_open = is_newly_open
      const page_title = props.page_title || extProps.page_title || document.title || ''
      if (page_title) {
        // 上报页面浏览事件时 如果有page_title 把值同步给终端
        addReferPage({ pageTitle: page_title })
      }
    }
    const { page_title = 'default', page_type = 'default', page_id = 'default' } = {
      ...props,
      ...extProps
    }
    this.page_title = page_title
    this.page_type = page_type
    this.page_id = page_id

    isInApp() && (await this.setAppReferAndTrackingId())
    // 进入页面时记录当前的refer  等onHide 时记录下一个页面的refer 并提前先讲埋点推送出去先
    isInMiniProgram() && this.setRefer(this.miniProgramRefer)
    // 缓存埋点数据，在触发回退事件时使用
    sessionStorage.setItem('PAGE_BURIED_POINT_DATA', JSON.stringify({ props, extProps }))
    this.send(sendKey, props, extProps, channel)
  }

  /**
   * 页面离开
   * @param pageThis
   * @param channel
   */
  // eslint-disable-next-line max-params
  pageOnHide(
    sendKey: SendKeyEnum,
    pageThis: any,
    props: Record<string, any>,
    extProps: Record<string, any>,
    channel: EnumChannel = EnumChannel.sr
  ) {
    // 计算当前页面停留耗时
    // console.log(
    //   '计算当前页面停留耗时',
    //   Date.now(),
    //   pageThis._page_start_time,
    //   'staytime:',
    //   Date.now() - pageThis._page_start_time
    // )
    const pageStayTime = pageThis._page_start_time ? Date.now() - pageThis._page_start_time : 0
    this.send(
      sendKey,
      {
        ...props,
        stay_time: `${pageStayTime}`
      },
      extProps,
      channel
    )
    /**
     * 小程序内的H5 打开小程序页面  会再打开方法内 添加上 当前页的 miniProgramNextRefer  不需要再在离开事件上触发 添加公参 这样会把当前页的路径拼接到埋点中
     * 小程序内的H5 打开H5 页面 就是 history.push 这种类型  在当前页添加上离开事件 手动触发离开事件
     * 要区分开 两种类型 新增一个参数 是否在H5
     */
    setTimeout(() => {
      extProps?.isH5 && isInMiniProgram() && this.setRefer(this.miniProgramNextRefer)
    }, 0)
  }

  /**
   * 页面下拉刷新
   * @param pageThis
   * @param channel
   */
  pageOnPullDownRefresh(pageThis: any, channel: EnumChannel = EnumChannel.sr) {
    this.send(SendKeyEnum.PagePullDownRefresh, {}, pageThis, channel)
  }

  /**
   * 页面上拉触底
   * @param pageThis
   * @param channel
   */
  pageOnReachBottom(pageThis: any, channel: EnumChannel = EnumChannel.sr) {
    this.send(SendKeyEnum.PageReachBottom, {}, pageThis, channel)
  }

  getCurrentRefer() {
    return {
      ref_page_title: `${this.page_title}`,
      ref_page_type: `${this.page_type}`,
      ref_page_id: `${this.page_id}`
    }
  }

  private getPageTitle(route: any) {
    return route?.title
  }

  private async getCurrentPageInfo(wxPageInstance?: any): Promise<PageInfo> {
    if (wxPageInstance) {
      return {
        title: this.getPageTitle(wxPageInstance.route),
        path: wxPageInstance.route,
        query: wxPageInstance.options
      }
    } else {
      const infos = {
        title: '',
        path: '',
        query: {},
        page_id: 'default'
      }
      if (isInApp()) {
        // const { cardType } = await getAppDataByBridge(['cardType'])
        return {
          ...infos
          // member_card_type: cardType ? CardTypeEnum[Number(cardType)] : 'default'
        }
      }
      return infos
    }
  }
  private async setRefer(refer?: RefPageInfo) {
    if (refer) {
      this.miniProgramRefer = refer
      srInstance.flush()
      this.setContext({ refer })
    } else {
      const refer = await getRefer()
      if (refer) {
        this.miniProgramRefer = refer
      }
      this.setContext({ refer })
    }
  }
}
const wxAppTrack = new WxAppTrack()
export { wxAppTrack, EnumChannel, SendKeyEnum }
