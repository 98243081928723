// 插入校验头
import { IInterceptor } from '@tencent/retailwe-common-libs-http-client'
import { getAppDataByBridge } from '@/common/jsBridge/jsBridgeApi'

const transUrlApiList: string[] = [
  '/v1/sams/sams-user/membership/trans/renew',
  '/v1/sams/sams-user/contract/alone_sign',
  '/v1/sams/sams-user/membership/trans/transferMainCard'
]

/** 注入渠道信息 */
export default function trackInfoHandler(): IInterceptor {
  return {
    name: 'trackInfoHandler',
    request: async (request) => {
      if (request.url && transUrlApiList.includes(request.url)) {
        const { trackInfo: trackInfoStr = '' } = await getAppDataByBridge(['trackInfo'])
        if (!trackInfoStr) return request
        const trackInfo = decodeURIComponent(trackInfoStr)
        if (!request.body) {
          request.body = {}
        }
        request.headers['track-info'] = trackInfo
      }
      return request
    }
  }
}
