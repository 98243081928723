import { apis } from '@/common/http-client'
import { getAppDataByBridge } from '@/common/jsBridge/jsBridgeApi'
import {
  InvoiceChannelEnum,
  RESPONSE_CODE,
  OrderTypeEnum,
  InvoiceTypeEnum,
  LAN_KEY,
  staticBaseUrl,
  CardStatusEnum,
  CardType,
  MemInfo
} from '@/config'
import { MessageDecrypt, randomNum, removeSpace } from '@/utils'
import { concat } from 'lodash-es'
import { makeAutoObservable, runInAction } from 'mobx'
import { Toast } from '@/components/Toast'
import Cookies from 'js-cookie'
import Session from '@/common/session'
import { navigateToByNative } from '@/common/jsBridge/nativeApp'
import { ElementType } from '@/common/mall-tracking/types'
import { element } from '@/common/mall-tracking'
const { VITE_DOMAIN } = import.meta.env

export enum PopupType {
  /** 会员过期 */
  MEMBER_EXPIRED = 1,
  /** 非会员 */
  NOT_MEMBER = 2,
  /** 员工卡 */
  STAFF_CARD = 3,
  /** 挽留弹窗 */
  CONTINUE = 4
}
// 心意卡兑换码生成状态
export enum CardGenerateStatus {
  /** 生成中 */
  GENERATION = 0, // 生成中
  /** 生成失败 */
  FAILED = 1, // 生成失败
  /** 生成成功 */
  SUCCESS = 2 // 生成成功
}
// 心意卡订单状态
export enum OrderStaus {
  WAITPAY = 0, // 待付款
  PAYFAIL = -1, // 支付失败
  PAYSUCC = 1, // 支付成功，待发货
  SENDDOING = 2, // 发货中
  SENDSUCC = 3, // 发货成功
  SENDFAIL = 4, // 发货失败
  REFUND = 5, // 已退款
  CLEAR = 6 // 已取消
}
export enum WISH_LIST_TYPE {
  /** 待领取 */
  UN_GET = 0,
  /** 待激活 */
  UN_ACTIVATE = 1,
  /** 已完成
   *  (已失效 & 已激活)
   */
  FINISHED = 2
}

export enum WISH_CARD_STATUS {
  /** 已失效 */
  LOSE_EFFECT = -1,
  /** 待领取 */
  UN_GET = 0,
  /** 待激活 */
  UN_ACTIVATE = 1,
  /** 已激活 */
  ACTIVITED = 2
}

export const WISH_LIST_STATUS_MAP: Record<any, any> = {
  [WISH_CARD_STATUS.LOSE_EFFECT]: '已失效',
  [WISH_CARD_STATUS.ACTIVITED]: '已激活',
  [WISH_CARD_STATUS.UN_ACTIVATE]: '待激活',
  [WISH_CARD_STATUS.UN_GET]: '待领取'
}

export const WISH_LIST_TYPE_MAP: Record<any, any> = {
  [WISH_LIST_TYPE.UN_GET]: '待领取',
  [WISH_LIST_TYPE.UN_ACTIVATE]: '待激活',
  [WISH_LIST_TYPE.FINISHED]: '已完成'
}

export enum WishCardTab {
  SEND = 0,
  RECEIVE = 1
}
export const WISH_CARD_TAB_AMP: Record<any, any> = {
  [WishCardTab.SEND]: '送出心意',
  [WishCardTab.RECEIVE]: '接收心意'
}

export interface WISH_CARD_TYPE_VOS {
  themeTypeName: string
  themeTypeNameEn: string
  themeTypeTitle: string
  themeTypeTitleEn: string
  wishcardThemeVOS: Array<WISH_CARD_VOS>
  showBtn?: boolean
  isUnfold?: boolean
}

export interface WISH_CARD_VOS {
  id: string
  backImg?: string
  selected?: boolean
  backCodeColor?: string
  moneyBackImg?: string
  themeName?: string
  themeTypeId: string
  thumbnailImg?: string
  themeStyleVO?: any
  themeTypeName?: string
}

const defaultTheme = {
  clubShareImg: `${staticBaseUrl}wishcard/theme/share.png`, // 分享缩略图 普通
  plusShareImg: `${staticBaseUrl}wishcard/theme/plus_share.png` // 分享缩略图 卓越
}

export enum IsPlus {
  /** 普通会员 */
  noPlus = 0,
  /** 卓越会员 */
  plus = 1
}

class WishcardStore {
  memberInfo: MemInfo = {}
  shareMiniProgramPath = '' // 分享小程序路径
  themePostionId = 1 // 选中的主题位置
  blessingId = ''
  orderStatus: OrderStaus = OrderStaus.WAITPAY // 心意卡订单状态
  themeDetail: any = {} // 心意卡主题详情
  showNotePopup = false // 心意卡弹窗
  popupType: PopupType = PopupType.MEMBER_EXPIRED // 心意卡弹窗类型 1：会员过期 2：非会员
  themeTypeId = '' // 选中的心意卡主题类型id
  themeId = '' // 选中的心意卡主题id
  orderNo = '' // 订单编号
  redeemCode = ''
  redeemId = ''
  encryptionInfo = ''
  cardGenerateStatus: CardGenerateStatus = CardGenerateStatus.GENERATION // 心意卡生成状态
  intervalCount = 0 // 心意卡订单轮询次数
  isPlus: IsPlus = 0 // 是否是PLUS会员 0普通会员 1-卓越会员
  timer: any = null
  orderPayResult: 'fail' | 'success' | '' = '' // 订单支付结果
  pageNum = 1
  totalPage = 1 // 总页数
  wishCardActiveTab = 0 // 心意卡 - 0：送出心意卡 1：接收心意卡
  activeCardStatusTab = 0 // -1-已失效，0-待领取，1-已领取，2-已激活
  /** 状态栏高度 */
  statusBarHeight = 0
  wishcardThemeTypeVos: Array<WISH_CARD_TYPE_VOS> = [] // 分类心意卡主题列表
  wishcardThemeTypeVosInit: Array<WISH_CARD_TYPE_VOS> = [] // 分类心意卡主题列表初始数据
  selectedWishCardId = '' // 选中主题id
  selectedthemeTypeId = '' // 选中主题分类id
  wishCardThemeError = false // 接口报错兜底页
  wishCardThemeLoading = true // 心意卡主题列表加载中
  isEn = false
  wishcardVOS = [] // 心意卡列表
  unGetWishCardList: Array<WISH_CARD_VOS> = [] // 0：送出心意卡-待领取心意卡列表
  unActivateWishCardList: Array<WISH_CARD_VOS> = [] // 0：送出心意卡-待激活心意卡列表
  finishedWishCardList: Array<WISH_CARD_VOS> = [] // 0：送出心意卡-已完成心意卡列表
  unActivateWishCardListV1: Array<WISH_CARD_VOS> = [] // 接收心意卡-待激活心意卡列表
  finishedWishCardListV1: Array<WISH_CARD_VOS> = [] // 接收心意卡-已完成心意卡列表
  wishCardPageNum = 1 // 心意卡列表页码
  wishCardTotalPage = 1 // 心意卡列表总页数
  wishCardListLoading = true // 心意卡列表加载中
  adgroupDataList = []
  wishCardItemorderNo = '' // 心意卡订单id

  /** 心意卡分类模板列表 */
  themeTypeList: Array<WISH_CARD_TYPE_VOS> = []
  /** 模板列表 */
  themeList: Array<WISH_CARD_VOS> = []
  /** 祝福语列表 */
  blessingList: { blessing: string; id: string }[] = []
  blessing = ''
  /** 选择的心意卡模板 */
  selectedTheme: WISH_CARD_VOS | undefined = undefined

  constructor() {
    makeAutoObservable(this)
    this.isEn = Cookies.get(LAN_KEY) === 'EN'
  }

  updateWishCardActiveTab = (tabId: number) => {
    this.wishCardActiveTab = tabId
  }
  updateActiveCardStatusTab = (tabId: number) => {
    this.activeCardStatusTab = tabId
  }
  async queryThemeList() {
    try {
      const { code, data } = await apis.wishcard.wishcardThemeList({})

      if (code === RESPONSE_CODE.SUCCESS) {
        this.themeTypeList = data.wishcardThemeTypeVos
        this.setTop3Theme()
      }
    } catch (error) {}
  }
  async queryBlessingList(blessing: string) {
    try {
      const { code, data } = await apis.wishcard.blessingList({})

      if (code === RESPONSE_CODE.SUCCESS) {
        this.blessingList = data.wishcardBlessingVOS
        if (blessing) {
          this.setBlessing(blessing)
        } else {
          this.updateBlessing()
        }
      }
    } catch (error) {}
  }

  setBlessing = (blessing: string) => {
    this.blessingId = 'default'
    this.blessing = blessing
  }
  updateBlessing = () => {
    const blessingList = this.blessingList
    const randomIndex: number = randomNum(0, blessingList.length - 1)
    this.blessing = blessingList[randomIndex].blessing
    this.blessingId = blessingList[randomIndex].id

    element(
      {
        type: ElementType.Browse,
        pageTitle: '心意卡',
        pageType: '个人中心页',
        elementId: 'expose_MbrshipGift_greetings',
        eventName: 'sam_h5_personalCenter_greetingCard_expose_MbrshipGift_greetings_browse'
      },
      {
        business: {
          business_name: this.blessing,
          business_type: 'default',
          business_id: this.blessingId
        }
      }
    )
    return { blessing: this.blessing, blessingId: this.blessingId }
  }
  async setStatusBarHeight() {
    const { statusBarHeight } = await getAppDataByBridge(['statusBarHeight'])
    this.statusBarHeight = Number(statusBarHeight)
  }

  /** 选择心意卡 */
  selectedWishCard = (id: string, themeTypeId: string, flag?: boolean) => {
    const wishcardThemeTypeVosNew = [...this.wishcardThemeTypeVos]
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < wishcardThemeTypeVosNew.length; i++) {
      wishcardThemeTypeVosNew[i]?.wishcardThemeVOS?.map((item: WISH_CARD_VOS) => {
        if (themeTypeId === item.themeTypeId && id === item.id) {
          flag
            ? (this.selectedWishCardId = id)
            : item.selected
            ? (this.selectedWishCardId = '')
            : (this.selectedWishCardId = id)
          flag
            ? (this.selectedthemeTypeId = item.themeTypeId)
            : item.selected
            ? (this.selectedthemeTypeId = '')
            : (this.selectedthemeTypeId = item.themeTypeId)
          item.selected = flag ? true : !item.selected
        } else {
          item.selected = false
        }
        return item
      })
    }
  }

  /** 处理主题初始展示数据 */
  setWishCardInitData = () => {
    const wishcardThemeTypeVosNew = this.wishcardThemeTypeVos.map((item: WISH_CARD_TYPE_VOS) => {
      item.showBtn = item.wishcardThemeVOS?.length > 4
      item.isUnfold = false
      const wishcardThemeVOSNew = item.wishcardThemeVOS.slice(0, 4)
      item.wishcardThemeVOS = wishcardThemeVOSNew
      return item
    })

    this.wishcardThemeTypeVos = wishcardThemeTypeVosNew
    this.selectedWishCard(this.selectedWishCardId, this.selectedthemeTypeId)
  }

  /** 展开/收起 */
  unfoldOrUpFun = (data: any) => {
    const { typeIndex, themeId, themeTypeId } = data || {}
    const wishcardThemeTypeVosNew = this.wishcardThemeTypeVos.map(
      (item: WISH_CARD_TYPE_VOS, index: number) => {
        if (index === typeIndex) {
          const wishcardThemeVOSNew = item.isUnfold
            ? item.wishcardThemeVOS.slice(0, 4)
            : this.wishcardThemeTypeVosInit[typeIndex].wishcardThemeVOS
          item.wishcardThemeVOS = wishcardThemeVOSNew
          item.isUnfold = !item.isUnfold
        }
        return item
      }
    )
    this.wishcardThemeTypeVos = wishcardThemeTypeVosNew
    this.selectedWishCard(themeId, themeTypeId, true)
  }

  setSelectItemIds(themeId: string, themeTypeId: string) {
    this.selectedWishCardId = themeId
    this.selectedthemeTypeId = themeTypeId
  }

  setNextWishcardListByPage() {
    this.wishCardPageNum = this.wishCardPageNum + 1
    this.setWishcardListByPage()
  }

  setWishCardPageNum() {
    this.wishCardPageNum = 1
  }

  setSendWishCardListData(isReset = false, wishcardVOS: WISH_CARD_VOS[]) {
    switch (this.activeCardStatusTab) {
      case WISH_LIST_TYPE.UN_GET:
        this.unGetWishCardList = isReset
          ? [...wishcardVOS]
          : concat(this.unGetWishCardList, wishcardVOS)
        break
      case WISH_LIST_TYPE.UN_ACTIVATE:
        this.unActivateWishCardList = isReset
          ? [...wishcardVOS]
          : concat(this.unActivateWishCardList, wishcardVOS)
        break
      case WISH_LIST_TYPE.FINISHED:
        this.finishedWishCardList = isReset
          ? [...wishcardVOS]
          : concat(this.finishedWishCardList, wishcardVOS)
        break
    }
  }

  setReceiveWishCardListData(isReset: boolean, wishcardVOS: WISH_CARD_VOS[]) {
    switch (this.activeCardStatusTab) {
      case WISH_LIST_TYPE.UN_ACTIVATE:
        this.unActivateWishCardListV1 = isReset
          ? [...wishcardVOS]
          : concat(this.unActivateWishCardListV1, wishcardVOS)
        break
      case WISH_LIST_TYPE.FINISHED:
        this.finishedWishCardListV1 = isReset
          ? [...wishcardVOS]
          : concat(this.finishedWishCardListV1, wishcardVOS)
        break
    }
  }
  /** 去激活心意卡 */
  goToActivateWishCard(id: string) {
    navigateToByNative(`/pages/registerMembership/index?redeemId=${id}`)
  }
  /** 去购买心意卡 */
  goToBuyWishCard(themeId?: string, themeTypeId?: string) {
    const url =
      themeTypeId && themeId
        ? `${VITE_DOMAIN}/sams/wishcard?themeId=${themeId}&themeTypeId=${themeTypeId}`
        : `${VITE_DOMAIN}/sams/wishcard`
    navigateToByNative(`sams://h5?url=${encodeURIComponent(url)}`)
  }
  /** 去心意卡详情 */
  goToWishCardDetail(orderNo: string) {
    navigateToByNative(`sams://orderDetail?orderNo=${orderNo}`)
  }

  /** 赠送心意卡 */

  async getShareId(shareKey = 'eventShareId') {
    const { UUID = '' } = await getAppDataByBridge(['UUID'])
    const now = new Date().getTime()
    return UUID ? `${shareKey}=${now}_${UUID}` : `${shareKey}=''`
  }

  async goSendWishCard(item: any) {
    const { redeemId, themeId, isPlus, wishcardThemeVO, orderNo } = item
    this.wishCardItemorderNo = orderNo
    let themeStyleVO
    if (wishcardThemeVO) {
      themeStyleVO = wishcardThemeVO.themeStyleVO
    }
    try {
      const res = await apis.wishcard.queryDonateKey({ redeemId: redeemId })
      const { code, data, msg } = res || {}

      if (code === RESPONSE_CODE.SUCCESS) {
        const { encryptionInfo } = res.data || {}
        let shareLinkImage = ''
        if (themeId && themeId !== '0' && wishcardThemeVO) {
          shareLinkImage = isPlus === 1 ? themeStyleVO?.plusShareImg : themeStyleVO?.clubShareImg
        } else {
          shareLinkImage = isPlus === 1 ? defaultTheme.plusShareImg : defaultTheme.clubShareImg
        }
        const shareId = await this.getShareId()

        const encryptionInfoStr = encryptionInfo && encodeURIComponent(encryptionInfo)
        const miniProgramPath = `/pages/membership/index?path=/pages/wishcard/receive/index&encryptionInfo=${encryptionInfoStr}&${shareId}`
        this.shareMiniProgramPath = miniProgramPath
        sr.app.invoke('configShareInfo', {
          shareTitle: '您的好友送您一张山姆心意卡',
          shareUrl: miniProgramPath,
          shareType: 'miniProgram',
          miniProgramPath, // 小程序链接
          sharePic: shareLinkImage,
          showFriendsCircle: false,
          showCreateImage: false,
          invokeShare: true,
          articleType: '心意卡'
        })
      }
    } catch (e) {}
  }

  /** 心意卡主题列表 */
  async setWishcardThemeList(themeId: string, themeTypeId: string) {
    this.setSelectItemIds(themeId, themeTypeId)
    try {
      const res = await apis.wishcard.wishcardThemeList({})
      const { code, data, msg } = res || {}
      if (code === RESPONSE_CODE.SUCCESS) {
        this.wishCardThemeLoading = false
        const { wishcardThemeTypeVos } = data || {}

        runInAction(() => {
          this.wishcardThemeTypeVos = wishcardThemeTypeVos
          this.wishcardThemeTypeVosInit = wishcardThemeTypeVos
        })
        this.setWishCardInitData()
      } else {
        this.wishCardThemeError = true
        this.wishCardThemeLoading = false
      }
    } catch (e) {
      this.wishCardThemeError = true
      this.wishCardThemeLoading = false
    }
  }

  /** 心意卡主题页广告 */
  async setWishcardAdgroup() {
    const storeList: any = await getAppDataByBridge(['storeInfoList'])
    try {
      const res = await apis.wishcard.wishcardAdgroup({
        adgroupSign: 'wishcardThemeAdv',
        storeList: storeList?.storeInfoList?.map((item: any) => item.storeId) || []
      })
      const { code, data, msg } = res || {}
      if (code === RESPONSE_CODE.SUCCESS) {
        const { adgroupDataList } = data || {}

        runInAction(() => {
          this.adgroupDataList = adgroupDataList
        })
      } else {
        console.log(msg)
      }
    } catch (e) {}
  }

  /** 分页查询用户名下的心意卡列表 */
  async setWishcardListByPage(isReset = false) {
    if (isReset) this.wishCardListLoading = true
    const params = {
      pageNum: this.wishCardPageNum,
      pageSize: 10,
      type: Number(this.wishCardActiveTab + 1),
      status: '',
      statusStr:
        this.activeCardStatusTab === WISH_LIST_TYPE.FINISHED
          ? '-1,2'
          : String(this.activeCardStatusTab)
    }
    Toast.loading('')
    try {
      const res = await apis.wishcard.wishcardListByPage(params)
      const { code, data, msg } = res || {}

      if (code === RESPONSE_CODE.SUCCESS) {
        const { wishcardVOS, count } = data || {}
        runInAction(() => {
          this.wishCardActiveTab === 0
            ? this.setSendWishCardListData(isReset, wishcardVOS)
            : this.setReceiveWishCardListData(isReset, wishcardVOS)
          this.wishcardVOS = isReset ? wishcardVOS : this.wishcardVOS.concat(wishcardVOS)
          this.wishCardTotalPage = count
        })
      } else {
        console.log(msg)
      }
      Toast.hide()
      this.wishCardListLoading = false
    } catch (e) {
      Toast.hide()
      this.wishCardListLoading = false
    }
  }

  /** 选择主题 */
  selectTheme(id: string, themeTypeId: string) {
    this.themeId = id
    this.themeTypeId = themeTypeId
    this.themeList.forEach((item, index) => {
      if (item.id === this.themeId) {
        this.themePostionId = index + 1
        this.selectedTheme = item
      }
    })
  }

  /** 前3个主题 */
  setTop3Theme() {
    let themeList: Array<WISH_CARD_VOS> = []
    let themeListId: string[] = []
    this.themeTypeList.forEach((themes) => {
      themes?.wishcardThemeVOS.forEach((item) => {
        item.themeTypeName = themes.themeTypeName
        if (item.id === this.themeId && item.themeTypeId === this.themeTypeId) {
          this.selectedTheme = item
        }
        if (item.id && themeListId.indexOf(item.id) === -1) {
          themeList.push(item)
          themeListId.push(item.id)
        }
      })
    })
    // this.themeList = themeList
    // const themeListTop3 = themeList.slice(0, 3)
    // this.themeList = themeList
    if (!this.themeId) {
      this.themeList = themeList.slice(0, 3)
      this.selectTheme(themeList[0].id, themeList[0].themeTypeId)
    } else {
      const index = themeList.findIndex((item) => item.id === this.themeId)
      if (index > 2 && this.selectedTheme) {
        const themeListTop3 = themeList.slice(0, 2)
        themeListTop3.unshift(this.selectedTheme)
        this.themeList = themeListTop3
      } else {
        themeList[index].themeTypeId = this.themeTypeId
        themeList[index].themeTypeName = this.selectedTheme?.themeTypeName
        const themeListTop3 = themeList.slice(0, 3)
        this.themeList = themeListTop3
      }
      this.selectTheme(this.themeId, this.themeTypeId)
    }
  }

  /** 立即支付 */
  async payNow() {
    console.log('立即支付')
    sr.app.invoke('showLoading', {
      showLoading: true
    })
    try {
      const res = await apis.membership.getMemberInfo()
      sr.app.invoke('showLoading', {
        showLoading: false
      })
      const { code, data } = res
      if (code === RESPONSE_CODE.SUCCESS) {
        const { cardStatus, cardType, bindMemCard, plusAssociate } = data
        this.memberInfo = data
        // 优先级  非会员 ->  员工卡 -> 过期
        if (!bindMemCard) {
          // 非会员
          this.popupType = PopupType.NOT_MEMBER
          this.setNotePopup(true)
          return
        } else if (plusAssociate || cardType === CardType.STAFF) {
          // 员工卡
          this.popupType = PopupType.STAFF_CARD
          this.setNotePopup(true)
          return
        } else if (cardStatus === CardStatusEnum.EXPIRED) {
          // 过期
          this.popupType = PopupType.MEMBER_EXPIRED
          this.setNotePopup(true)
          return
        }

        this.buyWishcard()
      }
    } catch (error) {
      sr.app.invoke('showLoading', {
        showLoading: false
      })
      console.error(error)
    }
  }
  setOrderNo(orderNo: string) {
    this.orderNo = orderNo
  }
  async queryOrderPayResult() {
    this.intervalCount = this.intervalCount + 1
    try {
      const res = await apis.wishcard.queryResult({ orderNo: this.orderNo })
      const { code, data } = res
      const { status, wishcardVO } = data
      this.orderStatus = status
      if (code === RESPONSE_CODE.SUCCESS) {
        if (
          status === OrderStaus.PAYFAIL ||
          status === OrderStaus.WAITPAY ||
          status === OrderStaus.CLEAR
        ) {
          console.log('支付失败')
          this.orderPayResult = 'fail'
        } else {
          const { blessing, wishcardThemeVO, isPlus, redeemCode, redeemId } = wishcardVO
          console.log('支付成功')
          this.orderPayResult = 'success'
          this.blessing = blessing
          this.isPlus = isPlus
          this.selectedTheme = wishcardThemeVO
          this.redeemCode = MessageDecrypt(redeemCode)
          this.redeemId = redeemId
          this.themeId = wishcardThemeVO.id
          this.themeTypeId = wishcardThemeVO.themeTypeId
          this.setNotePopup(false)
          if (status === OrderStaus.SENDDOING) {
            this.cardGenerateStatus = CardGenerateStatus.GENERATION
            // 生成中
            if (!this.timer) {
              this.timer = setInterval(() => {
                this.queryOrderPayResult()
              }, 3000)
            }
          }
          if (status === OrderStaus.SENDSUCC) {
            this.cardGenerateStatus = CardGenerateStatus.SUCCESS
            this.clearTimer()
          }
        }
      }
      if (this.intervalCount >= 3) {
        this.cardGenerateStatus = CardGenerateStatus.FAILED
        this.clearTimer()
      }
    } catch (error) {
      console.error(error)
    }
  }
  clearTimer() {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  }
  setThemeId(themeId: string) {
    this.themeId = themeId
  }
  async queryDonateKey() {
    try {
      const { code, data } = await apis.wishcard.donateKey({ redeemId: this.redeemId })
      if (code === RESPONSE_CODE.SUCCESS) {
        const { encryptionInfo } = data
        this.encryptionInfo = encryptionInfo
      }
    } catch (error) {}
  }
  setIsPlus(isPlus: IsPlus) {
    this.isPlus = Number(isPlus)
  }
  async buyWishcard() {
    this.blessing = removeSpace(this.blessing)
    try {
      const params = {
        isPlus: this.isPlus,
        blessing: this.blessing,
        themeId: this.themeId
      }
      const res = await apis.wishcard.buy(params)
      const { code, data } = res
      if (code === RESPONSE_CODE.SUCCESS) {
        const { amount, payInfo, orderNo } = data
        const isMidas = payInfo.PayInfo
        this.setOrderNo(orderNo)
        sr.app.invoke('commonPay', {
          payInfo: isMidas
            ? {
                midas: payInfo.PayInfo,
                payChannel: '2'
              }
            : {
                ...payInfo,
                payChannel: '1'
              },
          amount: amount,
          callback: async () => {
            this.queryOrderPayResult()
            const url = `${VITE_DOMAIN}/sams/wishcard/pay-result?themeId=${this.themeId}&isPlus=${
              this.isPlus
            }&themeTypeId=${
              this.themeTypeId
            }&orderNo=${orderNo}&amount=${amount}&blessing=${encodeURIComponent(this.blessing)}`
            navigateToByNative(`sams://h5?url=${encodeURIComponent(url)}`)
          }
        })
      }
    } catch (error) {}
  }
  setNotePopup(isShow: boolean) {
    this.showNotePopup = isShow
  }
  setPopupType(popupType: PopupType) {
    this.popupType = popupType
  }
  async queryThemeDetail(themeId: string) {
    if (themeId) {
      try {
        const { code, data } = await apis.wishcard.themeDetail({ themeId })
        if (code === RESPONSE_CODE.SUCCESS) {
          this.themeDetail = data
        }
      } catch (error) {}
    }
  }
}

export default new WishcardStore()
