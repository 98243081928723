// 页面分享传参
export interface PageShare {
  formType: string // 转发事件来源
  shareTitle: string // 转发标题
  sharePath?: string // 转发页面路径
  shareImageUrl?: string // 转发图片路径
  shareTo: string // timeline:朋友圈  friends 好友
  activityInfo?: any
  eventTrackingId?: string
  pageId?: any
  pageTitle?: string
  pageType?: string
  eventName?: string
  shareType?: string // 分享类型 link：链接（好友分享）；iamge：图片，webpage:分享页面
}

export enum ElementType {
  Browse = 'browse',
  Tap = 'tap',
  Change = 'change',
  Longpress = 'longpress',
  Confirm = 'confirm',
  Element = 'element',
  ExposeAds = 'expose_ads',
  ExposeSkuComponent = 'expose_sku_component'
}

// 元素事件传参
export interface Element {
  type: ElementType // 行为事件类型
  elementId: string // 元素唯一标识符
  eventTrackingId?: string // 埋点ID
  index?: string // 元素信息索引
  name?: string // 元素信息名字
  activityInfo?: object // 活动信息
  componentId?: string // 组件id
  componentName?: string // 组件名称
  pageId?: any
  pageTitle?: string
  pageType?: string
  eventName?: string
}

// 页面浏览
export interface BrowseAndLeavePage {
  eventTrackingId?: string // 埋点ID
  name?: string // 元素信息名字
  activityInfo?: object // 活动信息
  refer_page?: string
  stay_time?: string // 用户当前页面的停留时长，毫秒
  is_newly_open?: boolean // 表示用户进入页面时的场景，true：首次进入或重新打开; false：返回进入
}

// 商品页浏览、商品曝光、商品卡触发（即点击）传参
export interface CommoditySku {
  id?: string | number // 商品唯一标识符
  name?: string // 商品名
  spuId: string // 标准化产品单元的唯一标识符
  spuName: string // 标准化产品单元的名称
  skuCategory?: object[]
  storeId?: string | number // 发货门店id
  storeName?: string // 发货门店名称
  outerService?: OuterService // 第三方信息
  originalPrice?: string // 商品当前原价单价
  currentPrice?: string // 商品当前现价
  primaryImageUrl?: string // 商品主图路径
  /** 组件id */
  componentId?: string | number
  /** 组件名称 */
  componentName?: string
  /** 组件的位置信息 */
  componentPosition?: number | string
  /** 组件模版类型 */
  componentTemplate?: string
  /** 组件内元素在组件内的位置信息 */
  positionId?: number
  pageId?: string
  pageTitle?: string
  pageType?: string
  eventName?: string
  contentType?: string
  isGoodsExpose?: boolean
}

// 加购动作类型
export enum CartActionType {
  FirstAdd = 'first_add_to_cart', // 首次加车
  Append = 'append_to_cart', // 再次加车
  AppendInCart = 'append_to_cart_in_cart', // 购物车追加
  Remove = 'remove_from_cart', // 从购物车彻底移除
  Decrease = 'decrease_from_cart' // 购物车减购
}

// 加购传参
export interface SkuToCart {
  cartActionType: CartActionType // 动作类型
  skuId: string | number // 商品唯一标识符
  skuName: string // 商品名称
  spuId: string // 标准化产品单元的唯一标识符
  spuName: string // 标准化产品单元的名称
  skuCategory?: object[]
  storeId: string // 商品发货门店id；
  storeName: string // 商品发货门店名称
  originalPrice: string // 商品当前原价单价
  currentPrice: string // 商品当前现价
  skuNum: number // 本次动作生效之后购物车里的商品件数
  outerService?: OuterService // 第三方信息
}

// 领取优惠券传参
export interface GetCoupon {
  couponId: string // 优惠券批次在商家自有系统里的『唯一标识符』
  couponName: string // 优惠券名称
  couponInsId: string // 优惠券实例的唯一标识符
  couponInsName: string // 优惠券实例的名称
}

// 搜索传参
export interface Search {
  keyword: string // 搜索关键词
}

// 订单状态
export enum BuriedOrderStatus {
  CancelGive = 'cancel_give_order', // 因为主观或客观的原因，取消下单后进入取消状态
  CancelPay = 'cancel_pay', // 用户关闭支付密码浮层
  Pay = 'pay', // 用户发起支付
  Refund = 'refund', // 用户发起退货退款
  GiveOrder = 'give_order', // 用户提交订单
  Payed = 'payed' // 完成支付
}

// 订单状态变更（前端上报下单行为）传参
export interface Order {
  orderId: string | number // 用户侧订单号
  buriedOrderStatus: BuriedOrderStatus // 订单状态
  orderCreateTime: string | number // 订单创建时间
  orderAmt: string | number // 订单金额
  payAmt: string | number // 订单应付金额
  orderType?: string // 订单类型
}

// 页面信息
export interface PageInfo {
  title: string
  path: string
  query: object
  member_card_type?: string
}

// 页面转发事件自定义内容
export interface ShareMsgCustom {
  from?: string // 转发事件来源
  title?: string // 转发标题
  path?: string // 转发路径
  imageUrl?: string // 自定义图片路径
  [propName: string]: any
}

// 服务信息种类
export enum ServiceType {
  Search = 'search',
  Recommend = 'recommend'
}

// 第三方服务信息
export interface OuterService {
  type: OuterServiceType // 类别
  serviceType?: ServiceType // 推荐搜索位携带的第三方信息
  sceneId?: string
  algid?: number | string
  activityType?: string // 活动信息
  activityId?: string
  activityName?: string
}

// 埋点第三方服务信息种类
export enum OuterServiceType {
  outerServiceInfo = 'outerServiceInfo', // 推荐搜索位埋点携带的第三方服务信息
  activityInfo = 'activityInfo' // 活动信息
}

// 直播 跳转商详页
export interface Live2goodParams {
  activity_info: {
    activity_id: string | number
    activity_type: string
    activity_name: string
  }
}

// 任务组件曝光传参
export interface ExposeTaskParams {
  name: string
  component: {
    component_id?: string | number // 任务id
    component_name?: string // 任务名称
  }
}

export interface ExposeEquityParams {
  event_tracking_id: string
  name: string
  component: {
    component_name?: string
  }
}

// 邀请有礼组件曝光传参
export interface ExposeInviteActivityParams {
  type: string
  name: string
  event_tracking_id: string
  component: {
    component_id?: string | number // 任务id
    component_name?: string // 任务名称
  }
}

export interface Component {
  /** 组件id */
  componentId?: string | number
  /** 组件名称 */
  componentName?: string
  /** 组件的位置信息 */
  componentPosition?: number
  /** 组件内元素在组件内的位置信息 */
  positionId?: number
  /** 事件唯一id（由产品定义） */
  eventTrackingId?: string
}

/** 小程序初始化传入的refer结构 */
export interface RefPageInfo {
  ref_page_title: string
  ref_page_type: string
  ref_page_id: string
}
